import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';

import styles from './BuilderNameSection.module.scss';
import { TBuilderNameSectionProps, builderNameSectionMode } from './BuilderNameSection.types';
import { SvgIcon } from '../../components/SvgIcon';

const MAX_INPUT_LENGTH = 30;
const MIN_INPUT_WIDTH = 240;
const ALLOWED_CHARACTERS = /^[a-zA-Z0-9_\s-]+$/;

export const BuilderNameSection: FC<TBuilderNameSectionProps> = ({
                                                                   inputValue,
                                                                   inputPlaceholder,
                                                                   loading,
                                                                   mode,
                                                                   onInputChange,
                                                                   confirmChanges,
                                                                   externalClass,
                                                                   previewMode,
                                                                   additionFields,
                                                                   withoutTitleField,
                                                                 }) => {
  const { pathname } = useLocation();
  const inputGhostRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isInputActive, setIsInputActive] = useState(false);
  const [inputWidth, setInputWidth] = useState(MIN_INPUT_WIDTH);
  const [editModeInputValue, setEditModeInputValue] = useState(
      inputValue ? inputValue : ''
  );
  const [headerEditModeDisabled, setHeaderEditModeDisabled] = useState(false);

  useEffect(() => setEditModeInputValue(inputValue), [inputValue]);

  useEffect(() => {
    const ghostWidth = inputGhostRef?.current?.clientWidth || 0;
    setInputWidth(Math.max(ghostWidth, MIN_INPUT_WIDTH));
  }, [
    isInputActive,
    inputValue,
    editModeInputValue,
    loading,
    inputGhostRef?.current?.clientWidth,
  ]);

  const getAppropriateModeInputValue = (ghost: boolean) => {
    if (mode === builderNameSectionMode.EDIT && isInputActive)
      return editModeInputValue?.length
          ? editModeInputValue
          : `${ghost ? inputPlaceholder : ''}`;

    return inputValue?.length ? inputValue : `${ghost ? inputPlaceholder : ''}`;
  };

  const handleInputChange = (value: string) => {
    if (previewMode) return;

    // Apply validations for non-empty values
    if (value.length > 0) {
      let newValue = value; // Removed trim() to allow spaces
      if (newValue.length > MAX_INPUT_LENGTH) {
        newValue = newValue.slice(0, MAX_INPUT_LENGTH);
      }
      if (!ALLOWED_CHARACTERS.test(newValue)) {
        return;
      }

      if (mode === builderNameSectionMode.EDIT) {
        setEditModeInputValue(newValue);
        return;
      }
      onInputChange(newValue);
    } else {
      // Handle empty value case
      if (mode === builderNameSectionMode.EDIT) {
        setEditModeInputValue('');
      } else {
        onInputChange('');
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow Ctrl+A (Select All)
    if (e.ctrlKey && e.key === 'a') {
      e.currentTarget.select();
      return;
    }

    // Handle backspace
    if (e.key === 'Backspace') {
      const currentValue = e.currentTarget.value;
      if (currentValue.length === 1) {
        handleInputChange('');
        e.preventDefault();
      }
    }
  };

  const handleDirectInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e.target.value);
  };

  const discardChanges = () => {
    setIsInputActive(false);
    setEditModeInputValue(inputValue);
  };

  const handleChangesConfirm = () => {
    confirmChanges && confirmChanges(editModeInputValue);
    discardChanges();
  };

  return (
      <div className={classNames(styles.editBuilderNameSectionWrapper, externalClass)}>
        <div className={styles.builderHeader}>
          <div className={styles.inputContainer}>
            <p className={styles.inputGhost} ref={inputGhostRef}>
              {getAppropriateModeInputValue(true).replaceAll(' ', '.')}
            </p>
            {!withoutTitleField && (
                <input
                    ref={inputRef}
                    className={classNames({
                      [styles.inputActive]: isInputActive,
                      [styles.editMode]: mode === builderNameSectionMode.EDIT,
                    })}
                    id="builder_name_input"
                    type="text"
                    value={getAppropriateModeInputValue(false)}
                    onFocus={() => setIsInputActive(true)}
                    placeholder={inputPlaceholder}
                    onChange={handleDirectInputChange}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    style={{ width: `${inputWidth}px` }}
                    disabled={headerEditModeDisabled}
                />
            )}
            {mode === builderNameSectionMode.EDIT && isInputActive && !!getAppropriateModeInputValue(false).length && (
                <div className={styles.builderNameControls}>
                  <div className={styles.confirmNameUpdate} onClick={handleChangesConfirm}>
                    <SvgIcon src={'icons/arrow.svg'} color="primary-700" size={15} />
                  </div>
                  <div className={styles.discardNameUpdate} onClick={discardChanges}>
                    <SvgIcon src={'icons/arrow.svg'} color="gray-700" size={15} />
                  </div>
                </div>
            )}
          </div>
        </div>
        {process.env.REACT_APP_ENV_PLATFORM === 'dev' && <>{additionFields}</>}
      </div>
  );
};