import React from 'react';
import { Toast } from './Toast';
import styles from './Toast.module.scss';
import { useNotificationActions } from '../../store/notification/useNotificationActions';
import { useSelector } from 'react-redux';

export const ToastContainer: React.FC = () => {
    const { actionRemoveNotification } = useNotificationActions();
    const notifications = useSelector((state: any) => state.notification.notifications);

    const handleRemove = (id: string) => {
        actionRemoveNotification(id);
    };

    if (!notifications.length) return null;

    return (
        <div className={styles.container}>
            {notifications.map((notification: any) => (
                <Toast
                    key={notification.id}
                    {...notification}
                    onRemove={handleRemove}
                />
            ))}
        </div>
    );
};