import { all, call, put, takeLatest } from "redux-saga/effects";
import { MediasService } from "../../services/media/MediaService";
import {
  actionGetMediaStream,
  actionGetMediaStreamFailed,
  actionGetMediaStreamSuccess,
  actionUploadMedia,
  actionUploadMediaFailed,
  actionUploadMediaSuccess,
} from "./media.reducer";
import { actionAddNotification } from "../notification/notification.reducer";
import { auth } from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";

const fetchWrapper = async (url: string, customHeaders: Record<string, string> = {}) => {
  const currentUser = auth.currentUser;
  const clientId = process.env.REACT_APP_CLIENT_ID ?? 'default_client_id';
  const requestId = uuidv4();

  if (currentUser) {
    const access_token = await currentUser.getIdToken();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      ClientId: clientId,
      RequestId: requestId,
      ...customHeaders,
    };

    return fetch(url, { headers })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.blob().then((blob) => ({
            blob,
            statusText: response.statusText,
          }));
        });
  } else {
    throw new Error("User not authenticated");
  }
};

function* fetchVideoUrl(payload: { orgId: string; id: string }) {
  try {
    const url = `${process.env.REACT_APP_GATEWAY_URL}/public/media/stream/org/${payload.orgId}/media/${payload.id}`;

    const { blob } = yield call(fetchWrapper, url);

    const urlCreator = window.URL || window.webkitURL;
    const videoUrl = urlCreator.createObjectURL(blob);

    return videoUrl;
  } catch (error) {
    console.error("Error fetching video URL:", error);
    return null;
  }
}

function* fetchMediaSaga({ payload }: any): any {
  try {
    const videoUrl = yield call(fetchVideoUrl, payload);

    if (videoUrl) {
      yield put(actionGetMediaStreamSuccess(videoUrl));  // Dispatch success with video URL
    } else {
      yield put(actionGetMediaStreamFailed());  // Dispatch failure
    }
  } catch (error) {
    console.error("Error in fetchMediaSaga:", error);
    yield put(actionGetMediaStreamFailed());  // Dispatch failure on error
  }
}

function* uploadMediaSaga({ payload }: any): any {
  try {
    const formdata = new FormData();
    formdata.append("file", payload.file);
    formdata.append("userId", payload.userId);
    formdata.append("orgId", payload.orgId);
    formdata.append("name", payload.mediaName);
    formdata.append("tags", payload.tags);

    const response = yield call(MediasService.uploadMedia, formdata);

    yield put(actionUploadMediaSuccess(response));
    yield put(actionAddNotification({
      type: 'success',
      message: 'Media Uploaded Successfully'
    }));
  } catch (error) {
    console.log(error);
    yield put(actionUploadMediaFailed());
    yield put(actionAddNotification({
      type: 'error',
      message: 'Error occurred while uploading Media. Please try again in some time'
    }));
  }
}

export function* mediaWatcher() {
  yield all([
    takeLatest(actionGetMediaStream.type, fetchMediaSaga),
    takeLatest(actionUploadMedia.type, uploadMediaSaga),
  ]);
}
