import React from 'react';
import { Checkbox } from "../../../../components/Checkbox/Checkbox.component";
import { StatusBadge } from "../../../../components/StatusBadge/StatusBadge.component";
import { columnConfigType } from "../../../../components/Table/Table.types";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../../../components/Text";
import { capitalize } from "../../../../utils/capitalize";
import styles from './SelectScreenCluster.module.scss';
import { TClusterListData , TscreensListData} from "../../../../store/cluster/cluster.types";

interface TableColumnProps {
    isScreen: boolean;
    onNameClick: (name: string) => void;
    onSelectItem: (id: string, name: string) => void;
    selectedItems: string[];
}

function isScreenListData(data: TscreensListData | TClusterListData): data is TscreensListData {
    return 'status' in data;
}

export const getTableColumns = ({ isScreen, onNameClick, onSelectItem, selectedItems }: TableColumnProps): columnConfigType<TscreensListData | TClusterListData>[] => {
    const columns: columnConfigType<TscreensListData | TClusterListData>[] = [
        {
            id: 'name',
            name: isScreen ? 'Screen Name' : 'Cluster Name',
            cellRenderer: (data) => (
                <div className={styles.cellRenderer}>
                    <Checkbox
                        checked={selectedItems.includes(data.id.toString())}
                        id={`table-header-checkbox-${data.id}`}
                        onClick={() => onSelectItem(data.id.toString(), data.name)}
                    />
                    <Text
                        variant={TextVariantsEnum.Text_md}
                        fontWeight={FontWeightEnum.Normal}
                        color={'neutral-70'}
                        onClick={() => onNameClick(data.name)}
                    >
                        {capitalize(data.name)}
                    </Text>
                </div>
            )
        }
    ];

    if (isScreen) {
        columns.push(
            {
                id: 'status',
                name: 'Status',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            <StatusBadge status={isScreenListData(data) ? data.status?.toLowerCase() || 'unknown' : 'unknown'} />
                        </Text>
                    </div>
                )
            },
            {
                id: 'location',
                name: 'Location',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {isScreenListData(data) ? data.location || 'N/A' : 'N/A'}
                        </Text>
                    </div>
                )
            },
            {
                id: 'tags',
                name: 'Tags',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {isScreenListData(data) ? (data.tags || []).join(', ') || 'N/A' : 'N/A'}
                        </Text>
                    </div>
                )
            },
            {
                id: 'timing',
                name: 'Timing',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {isScreenListData(data) ? data.timing || 'N/A' : 'N/A'}
                        </Text>
                    </div>
                )
            }
        );
    } else {
        columns.push(
            {
                id: 'total_screens',
                name: 'Total Screens',
                cellRenderer: (data) => (
                    <div>
                        <Text variant={TextVariantsEnum.Text_sm} fontWeight={FontWeightEnum.Normal}>
                            {!isScreenListData(data) ? data.totalScreens || '0' : '0'}
                        </Text>
                    </div>
                )
            }
        );
    }

    return columns;
};