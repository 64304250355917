import { useState } from 'react';
import styles from './Account.module.scss';

interface PersonalInfo {
    firstName: string;
    lastName: string;
    email: string;
  }

  interface PasswordForm {
    currentPassword: string;
    newPassword: string;
    verifyPassword: string;
  }
  
  export const AccountComponent: React.FC = () => {
    const [personalInfo] = useState<PersonalInfo>({
      firstName: 'Harry',
      lastName: 'Short',
      email: 'short.harry@email.com'
    });
  
    const [passwordForm, setPasswordForm] = useState<PasswordForm>({
      currentPassword: '',
      newPassword: '',
      verifyPassword: ''
    });
  
    const [showPasswords, setShowPasswords] = useState({
      current: false,
      new: false,
      verify: false,
    });
  
    const handlePasswordChange = (field: keyof PasswordForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setPasswordForm(prev => ({
        ...prev,
        [field]: e.target.value
      }));
    };
  
    const togglePasswordVisibility = (field: keyof typeof showPasswords) => {
      setShowPasswords(prev => ({
        ...prev,
        [field]: !prev[field]
      }));
    };
  
    return (
      <div className={styles.accountWrapper}>
        <div className={styles.card}>
          <h2 className={styles.cardTitle}>Personal Info</h2>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label>First Name</label>
              <input
                type="text"
                value={personalInfo.firstName}
                disabled
                className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Last Name</label>
              <input
                type="text"
                value={personalInfo.lastName}
                disabled
                className={styles.input}
              />
            </div>
            <div className={styles.formGroup + ' ' + styles.fullWidth}>
              <label>Email</label>
              <input
                type="email"
                value={personalInfo.email}
                disabled
                className={styles.input}
              />
            </div>
            <button className={styles.editButton}>Edit</button>
          </div>
        </div>
  
        <div className={styles.card}>
          <h2 className={styles.cardTitle}>Login Password</h2>
          <div className={styles.formGroup}>
            <label>Current Password</label>
            <div className={styles.passwordInput}>
              <input
                type={showPasswords.current ? 'text' : 'password'}
                value={passwordForm.currentPassword}
                onChange={handlePasswordChange('currentPassword')}
                className={styles.input}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('current')}
                className={styles.eyeIcon}
              >
                👁️
              </button>
            </div>
          </div>
  
          <div className={styles.formGroup}>
            <label>New Password</label>
            <div className={styles.passwordInput}>
              <input
                type={showPasswords.new ? 'text' : 'password'}
                value={passwordForm.newPassword}
                onChange={handlePasswordChange('newPassword')}
                className={styles.input}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('new')}
                className={styles.eyeIcon}
              >
                👁️
              </button>
            </div>
            <span className={styles.helperText}>
              Create a password between 8-64 characters.
            </span>
          </div>
  
          <div className={styles.formGroup}>
            <label>Verify New Password</label>
            <div className={styles.passwordInput}>
              <input
                type={showPasswords.verify ? 'text' : 'password'}
                value={passwordForm.verifyPassword}
                onChange={handlePasswordChange('verifyPassword')}
                className={styles.input}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility('verify')}
                className={styles.eyeIcon}
              >
                👁️
              </button>
            </div>
          </div>
  
          <div className={styles.buttonGroup}>
            <button className={styles.saveButton}>Save</button>
            <button className={styles.cancelButton}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };