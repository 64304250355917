import React from 'react';
import styles from './TimeSlot.module.scss';

interface TimeSlotProps {
    row: string;
    column: string;
    isSelected: boolean;
    isDisabled: boolean;
    onSelect: () => void;
    onMouseDown: () => void;
    onMouseEnter: () => void;
    onMouseUp: () => void;
    onTouchStart: (e: React.TouchEvent) => void;
    onTouchMove: (e: React.TouchEvent) => void;
    onTouchEnd: () => void;
    'data-row'?: string;
    'data-column'?: string;
}

const TimeSlot: React.FC<TimeSlotProps> = ({
                                               row,
                                               column,
                                               isSelected,
                                               isDisabled,
                                               onSelect,
                                               onMouseDown,
                                               onMouseUp,
                                               onMouseEnter,
                                               onTouchStart,
                                               onTouchMove,
                                               onTouchEnd,
                                               'data-row': dataRow,
                                               'data-column': dataColumn
                                           }) => {
    const handleMouseDown = (e: React.MouseEvent) => {
        if (isDisabled) return;
        e.preventDefault();
        onMouseDown();
        onSelect();
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        if (isDisabled) return;
        e.preventDefault();
        onTouchStart(e);
        onSelect();
    };

    return (
        <div
            className={`${styles.timeSlot} ${isSelected ? styles.selected : ''} ${isDisabled ? styles.disabled : ''}`}
            onMouseDown={handleMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseUp={onMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            data-row={dataRow}
            data-column={dataColumn}
        />
    );
};

export default TimeSlot;