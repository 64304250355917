import {addDays, format, startOfDay} from "date-fns";
import { DatePicker } from "../../../../components/DatePicker/DatePicker.component";
import styles from "./ScheduleCampaign.module.scss";
import Button from "../../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../../components/Button/button.types";
import { timeSlots } from "../CreateCampaign.constants";
import classNames from "classnames";
import {
  FontWeightEnum,
  Text,
  TextVariantsEnum,
} from "../../../../components/Text";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import { Range } from "../../../../components/DatePicker/DatePicker.types";
import EventCalenderLarge from "../../../../components/EventCalenderLarge/EventCalenderLarge.component";

export const ScheduleCampaign = () => {
  const { actionSetSelectedDates, actionSetSelectedSlots } =
    useCampaignsActions();
  const { selectedDates, selectedSlots, SelectedCampaignType } = useCampaignsSelector();

  const onSelectTimeSlot = (timeSlot: string) => {
    if (selectedSlots.includes(timeSlot)) {
      actionSetSelectedSlots(selectedSlots.filter((slot) => slot !== timeSlot));
      return;
    }
    actionSetSelectedSlots([...selectedSlots, timeSlot]);
  };

  const handleDateChange = (date: Range) => {
    const startDate = format(date.startDate, "yyyy-MM-dd");
    const endDate = format(date.endDate, "yyyy-MM-dd");
    actionSetSelectedDates([startDate, endDate]);
  };

  return (
    <div className={styles.scheduleWrapper}>
          <EventCalenderLarge/>
    </div>
  );
};
