import React, { useEffect } from 'react';
import styles from './Toast.module.scss';
import { INotification } from '../../store/notification/notification.types';

interface ToastProps extends INotification {
    onRemove: (id: string) => void;
}

export const Toast: React.FC<ToastProps> = ({ id, type, message, onRemove }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onRemove(id);
        }, 5000);

        return () => clearTimeout(timer);
    }, [id, onRemove]);

    return (
        <div className={`${styles.toast} ${styles[type]}`}>
            <div className={styles.message}>{message}</div>
            <button className={styles.closeButton} onClick={() => onRemove(id)} aria-label="Close">
                ×
            </button>
        </div>
    );
};
