import React, { useRef, useEffect, useState } from 'react';
import { TooltipProps } from './tooltip.types';
import styles from './Tooltip.module.scss';

export const Tooltip: React.FC<TooltipProps> = ({
                                                    message,
                                                    show,
                                                    children
                                                }) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [alignment, setAlignment] = useState<'center' | 'left' | 'right'>('center');

    useEffect(() => {
        const checkPosition = () => {
            if (!tooltipRef.current || !wrapperRef.current) return;

            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const wrapperRect = wrapperRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            // Check if tooltip exceeds right screen boundary
            if (tooltipRect.right > viewportWidth) {
                setAlignment('right');
            }
            // Check if tooltip exceeds left screen boundary
            else if (tooltipRect.left < 0) {
                setAlignment('left');
            }
            // Default center alignment
            else {
                setAlignment('center');
            }
        };

        if (show) {
            // Use requestAnimationFrame to ensure DOM has been updated
            requestAnimationFrame(checkPosition);
        }

        // Add resize listener
        window.addEventListener('resize', checkPosition);
        return () => window.removeEventListener('resize', checkPosition);
    }, [show, message]);

    const getTooltipClassName = () => {
        const classes = [styles.tooltip];
        if (alignment === 'right') classes.push(styles.rightAligned);
        if (alignment === 'left') classes.push(styles.leftAligned);
        return classes.join(' ');
    };

    return (
        <div className={styles.tooltipWrapper} ref={wrapperRef}>
            {children}
            {show && message && (
                <div className={getTooltipClassName()} ref={tooltipRef}>
                    {message}
                </div>
            )}
        </div>
    );
};