import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import {
    ButtonTextSizeEnum,
    ButtonVariantEnum,
} from "../../../../components/Button/button.types";
import { Dropdown } from "../../../../components/Dropdown/Dropdown.component";
import {
    FontWeightEnum,
    Text,
    TextVariantsEnum,
} from "../../../../components/Text";
import styles from "./CreateClusterFilter.module.scss";
import { useClustersActions } from "../../../../store/cluster/useClusterActions";
import { useClustersSelector } from "../../../../store/cluster/useClusterSelector";

interface CreateClusterFilterProps {
    applyFilters: () => void;
}

interface StateCity {
    [key: string]: { label: string; value: string }[];
}

type OpenDropdown = 'state' | 'city' | null;

export const CreateClusterFilter: React.FC<CreateClusterFilterProps> = ({
                                                                            applyFilters,
                                                                        }) => {
    const { actionSetSelectedCity, actionSetSelectedState } = useClustersActions();
    const { selectedState, selectedCity } = useClustersSelector();
    const [openDropdown, setOpenDropdown] = useState<OpenDropdown>(null);

    const stateOptions = [
        { label: "Uttar Pradesh", value: "UP" },
        { label: "Haryana", value: "HR" },
        { label: "Karnataka", value: "KA" },
    ];

    const stateCityMapping: StateCity = {
        KA: [{ label: "Bangalore", value: "BLR" }],
        UP: [{ label: "Meerut", value: "MRT" }],
        HR: [{ label: "SRE", value: "SRE" }],
    };

    const handleStateChange = (value: string) => {
        actionSetSelectedState(value);
        actionSetSelectedCity(null);
        setOpenDropdown(null);
    };

    const handleCityChange = (value: string) => {
        actionSetSelectedCity(value);
        setOpenDropdown(null);
    };

    const handleStateDropdownOpen = (isOpen: boolean) => {
        setOpenDropdown(isOpen ? 'state' : null);
    };

    const handleCityDropdownOpen = (isOpen: boolean) => {
        setOpenDropdown(isOpen ? 'city' : null);
    };

    // Get available cities based on selected state
    const getCityOptions = () => {
        if (!selectedState) return [];
        return stateCityMapping[selectedState] || [];
    };

    return (
        <div className={styles.filterWrapper}>
            <div className={styles.filters}>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                >
                    Filters
                </Text>
                <Dropdown
                    options={stateOptions}
                    placeholder="State"
                    value={selectedState}
                    onChange={handleStateChange}
                    isOpen={openDropdown === 'state'}
                    onOpenChange={handleStateDropdownOpen}
                />
                <Dropdown
                    options={getCityOptions()}
                    placeholder="City"
                    value={selectedCity}
                    onChange={handleCityChange}
                    disabled={!selectedState}
                    isOpen={openDropdown === 'city'}
                    onOpenChange={handleCityDropdownOpen}
                />
            </div>
            <Button
                iconSize={12}
                text={"Apply"}
                onClick={applyFilters}
                variant={ButtonVariantEnum.primary}
                size={ButtonTextSizeEnum.md}
                className={styles.applyBtn}
            />
        </div>
    );
};