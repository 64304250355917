// TabSwitch.tsx
import React from 'react';
import styles from './TabSwitch.module.scss';

interface TabItem {
  label: string;
  value: string;
  icon?: React.ComponentType;
}

interface TabSwitchProps {
  tabs: TabItem[];
  activeTab: string;
  onTabChange: (value: string) => void;
  className?: string;
}

export const TabSwitch: React.FC<TabSwitchProps> = ({
                                                      tabs,
                                                      activeTab,
                                                      onTabChange,
                                                      className
                                                    }) => {
  return (
      <div className={`${styles.tabSwitch} ${className || ''}`}>
        {tabs.map(({ label, value, icon: Icon }) => (
            <button
                key={value}
                onClick={() => onTabChange(value)}
                className={`${styles.tab} ${activeTab === value ? styles.active : ''}`}
            >
              {Icon && <Icon />}
              <span>{label}</span>
            </button>
        ))}
      </div>
  );
};