import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import styles from './Settings.module.scss'
import {TabSwitch} from "../../components/Tabswitch/TabSwitch.component";
import {useState} from "react";
import {Account} from "./Account";
import {Billing} from "./Billing";
export const SettingsComponent = () => {

    const [activeTab, setActiveTab] = useState('account');

    const tabs = [
        { label: 'Account', value: 'account' },
        { label: 'Billing', value: 'billing' }
    ];

    console.log('activeTab', activeTab);
    return (
        <div className={styles.settingsWrapper}>
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    Settings
                </Text>
            </div>
            <div className={styles.contentWrapper}>
            <TabSwitch
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={setActiveTab}
                className={styles.tabSwitchWrapper}
            />
            {activeTab==='account' ? (
                <Account />
            ) : (
                <Billing />
            )}
            </div>
            </div>
    )
}