import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    actionFetchCampaigns,
    actionFetchMediaLibrary,
    actionSetSelectedMedia,
    resetCampaigns,
    actionFetchSelectableScreensList,
    actionFetchSelectableScreensListSuccess,
    actionFetchSelectableScreensListFailed,
    actionSetSelectedScreen,
    actionSetSelectedDates,
    actionSetSelectedSlots,
    actionSetSelectedCampaignType,
    actionAddClusterSlots,
    actionUpdateCreateCampaignPayload,
    actionScheduleCampaign,
    actionScheduleCampaignSuccess,
    actionScheduleCampaignFailed,
    actionCampaignSaveAsDraft,
    actionScheduleMerchantScreensCampaign,
    actionScheduleMerchantScreensCampaignSuccess,
    actionScheduleMerchantScreensCampaignFailed,
    actionUpdateCreateMerchantScreenCampaignPayload,
    actionFetchMerchantScreenCampaigns,
    actionFetchMerchantScreenCampaignsSuccess,
    actionFetchMerchantScreenCampaignsFailed,
    actionSetScreenDefaultMediaFailed,
    actionSetScreenDefaultMediaSucess,
    actionSetScreenDefaultMedia,
    actionFetchScreensList,
    actionFetchScreensListSuccess,
    actionFetchScreensListFailed,
    actionCancelCampaign,
    actionCancelCampaignSuccess,
    actionCancelCampaignFailed,
    actionGetCampaignInfo,
    actionGetCampaignInfoSuccess,
    actionGetCampaignInfoFailed,
    actionAddScreensSlots
} from './campaigns.reducer';

export const useCampaignsActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
        actionSetScreenDefaultMedia,
        actionSetScreenDefaultMediaSucess,
        actionSetScreenDefaultMediaFailed,
        actionCampaignSaveAsDraft,
        actionFetchCampaigns,
        actionFetchMediaLibrary,
        actionSetSelectedMedia,
        resetCampaigns,
        actionFetchSelectableScreensList,
        actionSetSelectedScreen,
        actionSetSelectedDates,
        actionSetSelectedSlots,
        actionSetSelectedCampaignType,
        actionAddClusterSlots,
        actionUpdateCreateCampaignPayload,
        actionScheduleCampaign,
        actionScheduleCampaignSuccess,
        actionScheduleCampaignFailed,
        actionScheduleMerchantScreensCampaign,
        actionScheduleMerchantScreensCampaignSuccess,
        actionScheduleMerchantScreensCampaignFailed,
        actionUpdateCreateMerchantScreenCampaignPayload,
        actionFetchMerchantScreenCampaigns,
        actionFetchMerchantScreenCampaignsSuccess,
        actionFetchMerchantScreenCampaignsFailed,
        actionFetchScreensList,
        actionFetchScreensListSuccess,
        actionFetchScreensListFailed,
        actionCancelCampaign,
        actionCancelCampaignSuccess,
        actionCancelCampaignFailed,
        actionGetCampaignInfo,
        actionGetCampaignInfoSuccess,
        actionGetCampaignInfoFailed,
        actionAddScreensSlots
    },
    dispatch
  );
};