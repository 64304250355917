import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification, NotificationState } from './notification.types';
import { v4 as uuidv4 } from 'uuid';

const initialState: NotificationState = {
    notifications: [],
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        actionAddNotification: (state, action: PayloadAction<Omit<INotification, 'id'>>) => {
            state.notifications.push({
                ...action.payload,
                id: uuidv4(),
            });
        },
        actionRemoveNotification: (state, action: PayloadAction<string>) => {
            state.notifications = state.notifications.filter(
                (notification) => notification.id !== action.payload
            );
        },
    },
});

export const { actionAddNotification, actionRemoveNotification } = notificationSlice.actions;
export default notificationSlice.reducer;