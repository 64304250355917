import React, { useEffect, useState } from "react";
import styles from "./SelectScreens.module.scss";
import { TSelectScreenProps } from "./SelectScreens.types";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import { requestStatusEnum } from "../../../../store/store.types";
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { Table } from "../../../../components/Table/Table.component";
import { SelectScreenTableColumns } from "./SelectScreens.config";

export const SelectScreen: React.FC = () => {
    const [startRecord, setStartRecord] = useState(0);

    const { actionSetSelectedScreen } = useCampaignsActions();
    const { selectedScreens , screensList} = useCampaignsSelector();

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        setStartRecord(startRecord);
    };

    const onSelectScreen = (selectedId: string) => {
        if (selectedScreens.includes(selectedId)) {
            actionSetSelectedScreen(selectedScreens.filter((id) => id !== selectedId));
            return;
        }
        actionSetSelectedScreen([...selectedScreens, selectedId]);
    };

    return (
        <div className={styles.ScreensWrapper}>
            <Table
                data={screensList?.data}
                columns={SelectScreenTableColumns(selectedScreens, onSelectScreen)}
                isLoading={false}
                totalRecords={screensList?.data?.length}
                startRecord={startRecord}
                recordsPerPage={20}
                onPaginationClick={onPaginationClick}
                emptyState={"No Screen Found"}
                className={styles.screenTable}
            />
        </div>
    );
};