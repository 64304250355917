import React, { useEffect, useState } from 'react';
import TimeSlot from './TimeSlot.component';
import styles from "./EventCalenderLarge.module.scss"
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import {useCampaignsSelector} from "../../store/campaigns/useCampaignsSelector";

const hours = [
    "09:00 - 10:00",
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 01:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
    "18:00 - 19:00",
    "19:00 - 20:00",
    "20:00 - 21:00",
    "21:00 - 22:00",
    "22:00 - 23:00",
];

const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
};

const getFormattedDate = (date: Date): string => {
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
};

const generateDates = (): string[] => {
    const indiaTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const currentDate = new Date(indiaTime);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();

    const dates: string[] = [];
    for (let i = 0; i < 30; i++) {
        const date = new Date(year, month, day + i);
        dates.push(getFormattedDate(date));
    }

    return dates;
};

const EventCalendarLarge: React.FC = () => {
    const { actionAddClusterSlots, actionAddScreensSlots } = useCampaignsActions();
    const [selectedTimestamps, setSelectedTimestamps] = useState<string[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isSelecting, setIsSelecting] = useState(true);
    const [dates, setDates] = useState<string[]>([]);
    const [lastTouchedCell, setLastTouchedCell] = useState<{ row: string; column: string } | null>(null);

    const { SelectedCampaignType } = useCampaignsSelector();

    useEffect(() => {
        setDates(generateDates());
    }, []);

    const isSlotDisabled = (row: string, column: string): boolean => {
        const [day, month] = row.split(' ').slice(0, 2);
        const [startHour] = column.split(' ')[0].split(':');
        const slotDate = new Date(`2024-${month} ${day} ${startHour}:00:00`);

        // Get current date and time in India timezone
        const indiaTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
        const now = new Date(indiaTime);

        // Check if the slot is on the current date
        const isCurrentDate = slotDate.getDate() === now.getDate() &&
            slotDate.getMonth() === now.getMonth() &&
            slotDate.getFullYear() === now.getFullYear();

        if (!isCurrentDate) return false;

        // Add 2 hours buffer to the current time
        const bufferTime = new Date(now);
        bufferTime.setHours(bufferTime.getHours());

        // If slot time is before buffer time on current date, disable it
        return slotDate < bufferTime;
    };

    const handleSelect = (row: string, column: string) => {
        if (isSlotDisabled(row, column)) return;

        setSelectedTimestamps(prevTimestamps => {
            const [day, month] = row.split(' ').slice(0, 2);
            const [startHour] = column.split(' ')[0].split(':');
            const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);

            const timestamp = (date.getTime() / 1000).toString();
            const isSelected = prevTimestamps.includes(timestamp);

            if (isSelecting) {
                return isSelected ? prevTimestamps : [...prevTimestamps, timestamp];
            } else {
                return isSelected ? prevTimestamps.filter(ts => ts !== timestamp) : prevTimestamps;
            }
        });
    };

    const handleTouchStart = (row: string, column: string, e: React.TouchEvent) => {
        if (isSlotDisabled(row, column)) return;

        e.preventDefault();
        setIsDragging(true);
        setLastTouchedCell({ row, column });
        const [day, month] = row.split(' ').slice(0, 2);
        const [startHour] = column.split(' ')[0].split(':');
        const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);
        const timestamp = (date.getTime() / 1000).toString();
        const isCurrentlySelected = selectedTimestamps.includes(timestamp);
        setIsSelecting(!isCurrentlySelected);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        e.preventDefault();
        if (!isDragging) return;

        const touch = e.touches[0];
        const element = document.elementFromPoint(touch.clientX, touch.clientY);
        if (!element) return;

        const timeSlotElement = element.closest('[data-row][data-column]');
        if (!timeSlotElement) return;

        const row = timeSlotElement.getAttribute('data-row');
        const column = timeSlotElement.getAttribute('data-column');

        if (row && column && lastTouchedCell && (row !== lastTouchedCell.row || column !== lastTouchedCell.column)) {
            if (!isSlotDisabled(row, column)) {
                setLastTouchedCell({ row, column });
                handleSelect(row, column);
            }
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        setLastTouchedCell(null);
    };

    const handleMouseDown = (row: string, column: string) => {
        if (isSlotDisabled(row, column)) return;

        setIsDragging(true);
        const [day, month] = row.split(' ').slice(0, 2);
        const [startHour] = column.split(' ')[0].split(':');
        const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);
        const timestamp = (date.getTime() / 1000).toString();
        const isCurrentlySelected = selectedTimestamps.includes(timestamp);
        setIsSelecting(!isCurrentlySelected);
    };

    const handleMouseEnter = (row: string, column: string) => {
        if (isDragging && !isSlotDisabled(row, column)) {
            handleSelect(row, column);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const isCellSelected = (row: string, column: string) => {
        const [day, month] = row.split(' ').slice(0, 2);
        const [startHour] = column.split(' ')[0].split(':');
        const date = new Date(`2024-${month} ${day} ${startHour}:00:00`);
        const timestamp = (date.getTime() / 1000).toString();
        return selectedTimestamps.includes(timestamp);
    };

    useEffect(() => {
        {SelectedCampaignType === 'myScreens' ? actionAddScreensSlots(selectedTimestamps) : actionAddClusterSlots(selectedTimestamps);}
    }, [selectedTimestamps]);

    return (
        <div className={styles.board}>
            <div className={styles.times}>
                <div className={`${styles.timeSlot} ${styles.firstTimeSlot}`}>
                    <span className={styles.timeLabel}></span>
                </div>
                {hours.map((hour, index) => (
                    <div key={index} className={styles.timeSlot}>
                        <span className={styles.timeLabel}>{hour}</span>
                    </div>
                ))}
            </div>
            <div className={styles.grid}>
                {dates.map((date, dateIndex) => (
                    <div key={dateIndex} className={styles.column}>
                        <div className={styles.dateCell}>
                            <span className={styles.dateLabel}>{date.split(' ')[0]} {date.split(' ')[1]}</span>
                        </div>
                        {hours.map((hour, hourIndex) => (
                            <div key={hourIndex} className={styles.cell}>
                                <TimeSlot
                                    row={date}
                                    column={hour}
                                    isSelected={isCellSelected(date, hour)}
                                    isDisabled={isSlotDisabled(date, hour)}
                                    onSelect={() => handleSelect(date, hour)}
                                    onMouseDown={() => handleMouseDown(date, hour)}
                                    onMouseEnter={() => handleMouseEnter(date, hour)}
                                    onMouseUp={handleMouseUp}
                                    onTouchStart={(e) => handleTouchStart(date, hour, e)}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={handleTouchEnd}
                                    data-row={date}
                                    data-column={hour}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventCalendarLarge;