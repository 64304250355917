import { call, takeLatest, all, put } from "redux-saga/effects";
import {
    actionCampaignSaveAsDraft,
    actionCampaignSaveAsDraftFailed,
    actionCampaignSaveAsDraftSuccess, actionCancelCampaign, actionCancelCampaignFailed, actionCancelCampaignSuccess,
    actionFetchCampaigns,
    actionFetchCampaignsFailed,
    actionFetchCampaignsSuccess,
    actionFetchMediaLibrary,
    actionFetchMediaLibraryFailed,
    actionFetchMediaLibrarySuccess,
    actionFetchMerchantScreenCampaigns,
    actionFetchMerchantScreenCampaignsFailed,
    actionFetchMerchantScreenCampaignsSuccess,
    actionFetchScreensList,
    actionFetchScreensListFailed,
    actionFetchScreensListSuccess,
    actionFetchSelectableScreensList,
    actionFetchSelectableScreensListFailed,
    actionFetchSelectableScreensListSuccess, actionGetCampaignInfo,
    actionScheduleCampaign,
    actionScheduleCampaignFailed,
    actionScheduleCampaignSuccess,
    actionScheduleMerchantScreensCampaign,
    actionSetScreenDefaultMedia,
    actionSetScreenDefaultMediaSucess
} from "./campaigns.reducer";
import { CampaignsService } from "../../services/campaigns/CampaignsService";
import {
    TActionCampaignSaveAsDraft, TActionCancelCampaign, TActionGetCampaignInfo,
    TActionGetCampaignsListPayload,
    TActionGetMediaLibraryPayload,
    TActionGetMerchantScreenCampaignsListPayload,
    TActionGetScreensListPayload,
    TActionGetSelectableScreensListPayload,
    TActionScheduleCampaignPayload,
    TActionScheduleMerchantScreenCampaignPayload,
    TActionSetScreenDefaultMediaPayload
} from "./campaigns.types";
import {useNavigate} from "react-router";
import {routesData} from "../../routing/routesData";
import {routes} from "../../routing/routes";
import { actionAddNotification } from "../notification/notification.reducer";

function* fetchCampaignsList({payload}: TActionGetCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getCampaigns, {page: payload.page, orgId: payload.orgId})
        yield put(actionFetchCampaignsSuccess({data}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchCampaignsFailed())
    }
}

function* fetchMerchantScreenCampaignsList({payload}: TActionGetMerchantScreenCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getMerchantScreenCampaigns, {page: payload.page, orgId: payload.orgId})
        yield put(actionFetchMerchantScreenCampaignsSuccess(data))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMerchantScreenCampaignsFailed())
    }
}

function* campaignSaveAsDraft({payload}: TActionCampaignSaveAsDraft): any {
    try {
        yield call(CampaignsService.campaignSaveAsDraft, payload);
        yield put(actionCampaignSaveAsDraftSuccess());
    } catch (error) {
        console.log('campaign save as draft error: ', error);
        yield put(actionCampaignSaveAsDraftFailed());
    }
}

function* cancelCampaignSaga({payload}: TActionCancelCampaign): any {
    try {
        yield call(CampaignsService.cancelCampaign, payload);
        yield put(actionCancelCampaignSuccess());
        yield put(actionAddNotification({
            type: 'success',
            message: 'Campaign Cancelled Successfully'
        }));
    } catch (error) {
        console.log('campaign save as draft error: ', error);
        yield put(actionCancelCampaignFailed());
        yield put(actionAddNotification({
            type: 'error',
            message: 'Error occurred while cancelling campaign. Please try again in some time'
        }));
    }
}

// getCampaignInfo
function* getCampaignInfoSaga({payload}: TActionGetCampaignInfo): any {
    try {
        yield call(CampaignsService.getCampaignInfo, payload);
        yield put(actionCancelCampaignSuccess());
    } catch (error) {
        console.log('campaign save as draft error: ', error);
        yield put(actionCancelCampaignFailed());
    }
}

function* scheduleCampaign({ payload }: TActionScheduleCampaignPayload): any {
    try {
        const data = yield call(CampaignsService.scheduleCampaign, payload);
        yield put(actionScheduleCampaignSuccess({data}));
        yield put(actionAddNotification({
            type: 'success',
            message: 'Campaign Scheduled Successfully'
        }));
        console.log('Data => ', data);
    } catch (error) {
        yield put(actionScheduleCampaignFailed());
        yield put(actionAddNotification({
            type: 'error',
            message: 'Error occurred while scheduling campaign. Please try again in some time'
        }));
        console.log('error: ', error);
    }
}

function* scheduleMerchantScreenCampaign({ payload }: TActionScheduleMerchantScreenCampaignPayload): any {
    try {
        const data = yield call(CampaignsService.scheduleCampaignMerchantScreen, payload);
        yield put(actionScheduleCampaignSuccess({data}));
        yield put(actionAddNotification({
            type: 'success',
            message: 'Campaign Scheduled Successfully'
        }));
        console.log('Data => ', data);
    } catch (error) {
        yield put(actionScheduleCampaignFailed());
        yield put(actionAddNotification({
            type: 'error',
            message: 'Error occurred while scheduling campaign. Please try again in some time'
        }));
        console.log('error: ', error);
    }
}

function* fetchMediaLibrary({payload}: TActionGetMediaLibraryPayload): any{
    try {
        const data = yield call(CampaignsService.getVerifiedMediaLibrary, payload)
        yield put(actionFetchMediaLibrarySuccess({
            data: data.response.mediaList,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMediaLibraryFailed())
    }
}

function* fetchSelectableScreensList({payload}: TActionGetSelectableScreensListPayload): any{
    try {
        const data= yield call(CampaignsService.getSelectableScreensList, {page: payload.page, state: payload?.state, city: payload?.city})
        yield put(actionFetchSelectableScreensListSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchSelectableScreensListFailed())
    }
}

function* fetchScreensList({payload}: TActionGetScreensListPayload): any{
    try {
        const data= yield call(CampaignsService.getScreensList, {page: payload?.page, userId: payload?.userId})
        yield put(actionFetchScreensListSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchScreensListFailed())
    }
}

function* setScreenDefaultMedia({payload}: TActionSetScreenDefaultMediaPayload): any{
    try {
        const data= yield call(CampaignsService.setScreenDefaultmedia, payload)
        console.log('Data => ', data);
        yield put(actionSetScreenDefaultMediaSucess());
        yield put(actionAddNotification({
            type: 'success',
            message: 'Default media added successfully'
        }));
    } catch (error) {
        console.log(error);
        yield put(actionSetScreenDefaultMediaSucess());
        yield put(actionAddNotification({
            type: 'error',
            message: 'Error occurred while adding default Media. Please try again in some time'
        }));
    }
}

export function* campaignsWatcher() {
    yield all([
        takeLatest(actionFetchCampaigns.type, fetchCampaignsList),
        takeLatest(actionFetchMediaLibrary.type, fetchMediaLibrary),
        takeLatest(actionFetchScreensList.type, fetchScreensList),
        takeLatest(actionFetchSelectableScreensList.type, fetchSelectableScreensList),
        takeLatest(actionCampaignSaveAsDraft.type, campaignSaveAsDraft),
        takeLatest(actionScheduleCampaign.type, scheduleCampaign),
        takeLatest(actionScheduleMerchantScreensCampaign.type, scheduleMerchantScreenCampaign),
        takeLatest(actionFetchMerchantScreenCampaigns.type, fetchMerchantScreenCampaignsList),
        takeLatest(actionSetScreenDefaultMedia.type,setScreenDefaultMedia),
        takeLatest(actionCancelCampaign.type,cancelCampaignSaga),
        takeLatest(actionGetCampaignInfo.type,getCampaignInfoSaga),
    ])
}