import React, { useState } from "react";
import { auth } from "../../config/firebase";
import { Input } from "../../components/Input";
import { Text, TextVariantsEnum } from "../../components/Text";
import styles from "./forgotPassword.module.scss";
import { validateEmail } from "../../utils/validation";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router";
import { routes } from "../../routing/routes";

export const ForgotPasswordComponent: React.FC = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate(routes().login);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setError("Please enter a valid email address");
            return;
        }

        setIsLoading(true);
        try {
            // Enhanced actionCodeSettings
            const actionCodeSettings = {
                // URL you want to redirect back to. The domain (www.example.com) for this
                // URL must be whitelisted in the Firebase Console.
                url: `${window.location.origin}/reset-password?email=${email}`,
                // This must be true for email link sign-in
                handleCodeInApp: true,
                // iOS app settings (optional)
                iOS: {
                    bundleId: 'com.yourapp.ios'
                },
                // Android app settings (optional)
                android: {
                    packageName: 'com.yourapp.android',
                    installApp: true,
                    minimumVersion: '12'
                },
                // Dynamic link settings (optional)
                dynamicLinkDomain: 'yourapp.page.link'
            };

            await auth.sendPasswordResetEmail(email, actionCodeSettings);

            // More descriptive success message
            setMessage(
                "Password reset link has been sent to your email address. " +
                "If you don't see it in your inbox, please check your spam folder. " +
                "The link will expire in 1 hour."
            );
            setError("");

            // Optional: Log the attempt for debugging
            console.log(`Password reset email sent to ${email}`);

        } catch (err: any) {
            // More detailed error handling
            console.error('Password reset error:', err);

            // More specific error messages based on error code
            if (err.code === 'auth/user-not-found') {
                setError("No account exists with this email address.");
            } else if (err.code === 'auth/invalid-email') {
                setError("The email address is not valid.");
            } else if (err.code === 'auth/too-many-requests') {
                setError("Too many attempts. Please try again later.");
            } else {
                setError(`Failed to send reset email: ${err.message || 'Please try again.'}`);
            }
            setMessage("");
        }
        setIsLoading(false);
    };

    return (
        <div className={styles.forgotPasswordContainer}>
            <Text
                variant={TextVariantsEnum.Display_sm}
                className={styles.title}
            >
                Reset Password
            </Text>
            <Text
                variant={TextVariantsEnum.Text_sm}
                className={styles.subtitle}
            >
                Enter your email address and we'll send you a link to reset your password.
            </Text>

            <form onSubmit={handleSubmit} className={styles.form}>
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    width="440px"
                />

                {error && (
                    <Text
                        variant={TextVariantsEnum.Text_xs}
                        color="error"
                        className={styles.error}
                    >
                        {error}
                    </Text>
                )}

                {message && (
                    <Text
                        variant={TextVariantsEnum.Text_xs}
                        color="success"
                        className={styles.success}
                    >
                        {message}
                    </Text>
                )}

                <div className={styles.buttons}>
                    <Button
                        text="Send Reset Link"
                        onClick={() => handleSubmit}
                        variant={ButtonVariantEnum.primary}
                        size={ButtonTextSizeEnum.md}
                        width="440px"
                        loading={isLoading}
                        disabled={!email || isLoading}
                    />
                    <Button
                        text="Back to Login"
                        onClick={handleBackToLogin}
                        variant={ButtonVariantEnum.secondary}
                        size={ButtonTextSizeEnum.md}
                        width="440px"
                    />
                </div>
            </form>
        </div>
    );
};