import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./CreateCampaign.module.scss";
import { builderNameSectionMode } from "../../../features/BuilderNameSection/BuilderNameSection.types";
import { routes } from "../../../routing/routes";
import { CreateHeader } from "../../../features/CreateHeader/CreateHeader.component";
import Button from "../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../components/Button/button.types";
import { Stepper } from "../../../components/Stepper/Stepper.component";
import { SelectScreen } from "./SelectScreen/SelectScreenCluster.component";
import { SelectMedia } from "./SelectMedia/SelectMedia.component";
import { useCampaignsActions } from "../../../store/campaigns/useCampaignsActions";
import { ScheduleCampaign } from "./ScheduleCampaign/ScheduleCampaign.component";
import { useCampaignsSelector } from "../../../store/campaigns/useCampaignsSelector";
import {useClustersSelector} from "../../../store/cluster/useClusterSelector";
import {useLoginSelector} from "../../../store/login/useLoginSelectors";
import {useClustersActions} from "../../../store/cluster/useClusterActions";
import {requestStatusEnum} from "../../../store/store.types";
import {Spinner} from "../../../components/Spinner/Spinner.component";
import { Tooltip } from '../../../components/Tooltip/Tooltip.component';

export const CreateCampaign: React.FC = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");

  const { actionResetSelectedCluster } = useClustersActions();
  const { resetCampaigns, actionUpdateCreateCampaignPayload, actionUpdateCreateMerchantScreenCampaignPayload, actionScheduleCampaign,actionScheduleMerchantScreensCampaign, actionCampaignSaveAsDraft} = useCampaignsActions();
  const {
    selectedMedia,
    selectedScreens,
    selectedDates,
    selectedSlots,
      selectedClusterSlots,
      selectedScreenSlots,
    SelectedCampaignType,
      selectedMediaLength,
      createCampaignPayload,
      createMerchantScreenCampaignPayload,
      campaignScheduleStatus,
    campaignSaveAsDraftStatus,
    campaignMerchantScreenScheduleStatus
  } = useCampaignsSelector();

  const { selectedClusters } = useClustersSelector();
  const { userData } = useLoginSelector();

  console.log('campaignSaveAsDraftStatus => ', campaignSaveAsDraftStatus);

  useEffect(() => {
    if(campaignScheduleStatus === requestStatusEnum.SUCCESS) navigate(routes().campaign);
  }, [campaignScheduleStatus]);

  useEffect(() => {
    actionUpdateCreateCampaignPayload({
      userId: userData?.userId,
      orgId: userData?.ordId
    });
    actionUpdateCreateMerchantScreenCampaignPayload({
      userId: userData?.userId,
      orgId: userData?.ordId
    });
    return () => {
      resetCampaigns();
      actionResetSelectedCluster();
    };
  }, []);

  useEffect(() => {
    if(campaignSaveAsDraftStatus === requestStatusEnum.SUCCESS) navigate(routes().campaign);
  }, [campaignSaveAsDraftStatus]);

  useEffect(() => {
    if (inputValue) {
      actionUpdateCreateCampaignPayload({
        name: inputValue,
      });
      actionUpdateCreateMerchantScreenCampaignPayload({
        name: inputValue,
      });
    }
  }, [inputValue]);

  useEffect(() => {
    if (selectedMedia) {
      actionUpdateCreateCampaignPayload({
        mediaId: selectedMedia,
        mediaLengthInSec: selectedMediaLength,
      });
      actionUpdateCreateMerchantScreenCampaignPayload({
        mediaId: selectedMedia,
        mediaLengthInSec: selectedMediaLength,
      });
    }
  }, [selectedMedia]);

  useEffect(() => {
    if (selectedClusters) {
      actionUpdateCreateCampaignPayload({
        clusters: selectedClusters,
      });
    }
  }, [selectedClusters]);

  useEffect(() => {
    if (selectedScreens) {
      actionUpdateCreateMerchantScreenCampaignPayload({
        screens: selectedScreens,
      });
    }
  }, [selectedScreens]);

  useEffect(() => {
    if(SelectedCampaignType === 'myScreens') {
      if (selectedScreenSlots?.length > 0) {
        actionUpdateCreateMerchantScreenCampaignPayload({ slots: selectedScreenSlots });
        const lastSlotTimestamp = selectedScreenSlots[selectedScreenSlots.length - 1];
        const endTimeTimestamp = (parseInt(lastSlotTimestamp) + 3600).toString();
        actionUpdateCreateMerchantScreenCampaignPayload({
          startTime: selectedScreenSlots[0]?.toString(),
          endTime: endTimeTimestamp
        });
      }
    } else {
      if (selectedClusterSlots?.length > 0) {
        actionUpdateCreateCampaignPayload({
          slots: selectedClusterSlots,
        });
        const lastSlotTimestamp = selectedClusterSlots[selectedClusterSlots.length - 1];
        const endTimeTimestamp = (parseInt(lastSlotTimestamp) + 3600).toString();

        actionUpdateCreateCampaignPayload({
          startTime: selectedClusterSlots[0]?.toString(),
          endTime: endTimeTimestamp
        });
      }
    }
  }, [selectedClusterSlots, selectedScreenSlots]);

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const onCloseClick = () => {
    localStorage.removeItem('SelectedCampaignType');
    navigate(routes().campaign);
  };

  const onStepClick = (step: number) => {
    setCurrentStep(step);
  };

  const onNextClick = () => {
    if (currentStep < 3) setCurrentStep(currentStep + 1)
    else {
      if(SelectedCampaignType === "myScreens") actionScheduleMerchantScreensCampaign(createMerchantScreenCampaignPayload)
      else actionScheduleCampaign(createCampaignPayload);
    };
  };
  const onBackClick = () => {
    if (currentStep === 1) onCloseClick();
    else setCurrentStep(currentStep - 1);
  };
  const onDraftSave = () => {
    actionCampaignSaveAsDraft(createCampaignPayload);
  };

  const validateSaveAsDraft = useMemo(() => {
    if (!inputValue) return false;
    switch (currentStep) {
      case 1:
        return !!selectedMedia;
      default:
        return true;
    }
  },[inputValue, selectedMedia])

  const getTooltipMessage = (currentStep: number): string => {
    switch (currentStep) {
      case 1:
        if (!selectedMedia) {
          return "Please select the media. If you don't see the media list, make sure the media is verified";
        }
        break;
      case 2:
        if (SelectedCampaignType === 'myScreens' && !selectedScreens?.length) {
          return "Please select the screens to proceed";
        }
        if (SelectedCampaignType !== 'myScreens' && !selectedClusters?.length) {
          return "Please select the Cluster to proceed";
        }
        break;
      case 3:
        if (!inputValue) {
          return "Please add the campaign name to proceed";
        }
        if (SelectedCampaignType === 'myScreens' && !selectedScreenSlots?.length) {
          return "Please select the Screen slots to Schedule campaign";
        }
        if (SelectedCampaignType !== 'myScreens' && !selectedClusterSlots?.length) {
          return "Please select the Cluster slots to Schedule campaign";
        }
        break;
    }
    return "";
  };

  const validateScreen = useMemo(() => {
    switch (currentStep) {
      case 1:
        return !!selectedMedia;
      case 2:
        return SelectedCampaignType === 'myScreens' ? !!selectedScreens?.length : !!selectedClusters?.length;
      case 3:
        // Add name validation only for the final step
        return (
            !!inputValue &&
            (SelectedCampaignType === 'myScreens' ? !!selectedScreenSlots?.length : !!selectedClusterSlots?.length)
        );
      default:
        return true;
    }
  }, [
    currentStep,
    inputValue,
    selectedMedia,
    selectedScreens,
    selectedClusters,
    selectedClusterSlots,
    selectedScreenSlots
  ]);

  const steps = [
    { label: "Setup", component: SelectMedia },
    {
        label: SelectedCampaignType === 'myScreens' ? 'Select Screens' : 'Select Cluster',
        component: SelectScreen 
    },
    { label: "Schedule", component: ScheduleCampaign },
];


  const CurrentComponent = useMemo(() => {
    return steps?.[currentStep - 1].component;
  }, [currentStep]);

  const footer = (
      <div className={styles.footer}>
        <Button
            variant={ButtonVariantEnum.secondary}
            text="Back"
            onClick={onBackClick}
            height={"32px"}
            width={"78px"}
        />
        {SelectedCampaignType === 'screenHub' && (
            <Button
                variant={ButtonVariantEnum.secondary}
                text="Save As Draft"
                onClick={onDraftSave}
                height={"32px"}
                width={"120px"}
                disabled={!validateSaveAsDraft}
            />
        )}
        <Tooltip
            message={getTooltipMessage(currentStep)}
            show={!validateScreen}
        >
          <Button
              variant={ButtonVariantEnum.primary}
              text={currentStep === 3 ? "Publish" : "Next"}
              onClick={onNextClick}
              height={"32px"}
              width={"78px"}
              disabled={!validateScreen}
          />
        </Tooltip>
      </div>
  );
  
  return (
        <div>
          {(campaignMerchantScreenScheduleStatus === requestStatusEnum.PROGRESS || campaignScheduleStatus === requestStatusEnum.PROGRESS) && ( <div className={styles.campaignLoader}>
      <Spinner />
    </div>
      )}
          <CreateHeader
            headerText="Create New Campaign"
            name={inputValue}
            setName={onInputChange}
            nameFieldPlaceholder={"Enter Campaign Name"}
            onCloseClick={onCloseClick}
            mode={builderNameSectionMode.CREATE}
            footer={footer}
          />
          <div className={styles.currentCompWrapper}>
            <Stepper
              steps={steps}
              currentStep={currentStep}
              // onStepClick={onStepClick}
            />
            <CurrentComponent />
          </div>
        </div>
  );
};
