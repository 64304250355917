import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionAddNotification, actionRemoveNotification } from './notification.reducer';

export const useNotificationActions = () => {
    const dispatch = useDispatch();

    return bindActionCreators(
        {
            actionAddNotification,
            actionRemoveNotification,
        },
        dispatch
    );
};