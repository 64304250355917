import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from './login/login.reducer';
import campaignsReducer from './campaigns/campaigns.reducer';
import mediaReducer from './media/media.reducer';
import clustersReducer from './cluster/cluster.reducer';
import notificationReducer from './notification/notification.reducer';

const rootReducers = combineReducers({
    login: loginReducer,
    campaigns: campaignsReducer,
    media: mediaReducer,
    clusters: clustersReducer,
    notification: notificationReducer,
});

export default rootReducers;