import React, { useRef, useState, useEffect } from 'react';
import { SvgIcon } from '../SvgIcon';
import { Text, TextVariantsEnum } from '../Text';
import styles from './OptionsMenu.module.scss';
import { OptionItem } from './OptionsMenu.types';

interface OptionsMenuProps {
    options: OptionItem[];
    className?: string;
}

export const OptionsMenu: React.FC<OptionsMenuProps> = ({ options, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleOptionClick = (action: () => void) => {
        action();
        setIsOpen(false);
    };

    return (
        <div className={`${styles.optionsMenuWrapper} ${className}`} ref={menuRef}>
            <div
                className={styles.triggerButton}
                onClick={() => setIsOpen(!isOpen)}
            >
                <SvgIcon
                    src="icons/three_dots_icon.svg"
                    size={16}
                    color="neutral-50"
                />
            </div>
            {isOpen && (
                <div className={styles.optionsDropdown}>
                    {options.map((option, index) => (
                        <button
                            key={index}
                            className={styles.optionItem}
                            onClick={() => handleOptionClick(option.action)}
                            disabled={option.disabled}
                        >
                            {option.icon && (
                                <SvgIcon
                                    src={option.icon}
                                    size={16}
                                    color="neutral-50"
                                    className={styles.optionIcon}
                                />
                            )}
                            <Text
                                variant={TextVariantsEnum.Text_sm}
                                className={styles.optionLabel}
                            >
                                {option.label}
                            </Text>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};