import React from "react";

import styles from "./TablePagination.module.scss";
import { TablePaginationProps } from "./TablePagination.types";
import { ButtonVariantEnum } from "../Button/button.types";
import Button from "../Button/Button";
import generatePagination from "../../utils/generatePagination";
import classNames from "classnames";
import { FontWeightEnum, Text, TextVariantsEnum } from "../Text";

export const TablePagination: React.FC<TablePaginationProps> = ({
  totalRecords,
  startRecord,
  recordsPerPage,
  onPaginationClick,
  itemsPerPageOptions = [5, 10, 20, 50],
  immutableRecordPerPage,
}) => {
  const { pages = [], currentPage } = generatePagination(
    totalRecords,
    startRecord,
    recordsPerPage
  );

  if (pages.length === 0) return null;

  return (
    <div className={styles.paginationWrapper}>
      {/*<div className={styles.countBlock}>
        <Text
          variant={TextVariantsEnum.Text_sm}
          fontWeight={FontWeightEnum.Normal}
          color="neutral-50"
        >
          Showing result
        </Text>
        <Text
          variant={TextVariantsEnum.Text_sm}
          fontWeight={FontWeightEnum.Bold}
          color="neutral-50"
        >
          {`${startRecord}-${startRecord + recordsPerPage}`}
        </Text>
      </div>*/}

      <div className={styles.pagination}>
        <Button
          variant={ButtonVariantEnum.secondary}
          icon={"/icons/arrow.svg"}
          iconSize={8}
          iconColor={"black"}
          onClick={() =>
            onPaginationClick(startRecord - recordsPerPage, recordsPerPage)
          }
          disabled={currentPage === "1"}
          className={classNames(styles.previous, styles.pageBtn)}
        />
        {pages.map((page, i) => (
          <Button
            variant={ButtonVariantEnum.secondary}
            key={`${page}_${i}`}
            text={`${page}`}
            onClick={
              page === "..."
                ? () => null
                : () =>
                    onPaginationClick(
                      (parseInt(`${page}`, 10) - 1) * recordsPerPage,
                      recordsPerPage
                    )
            }
            className={classNames(styles.pageBtn, {
              [styles.currentPage]: page === currentPage,
            })}
          />
        ))}
        <Button
          variant={ButtonVariantEnum.secondary}
          icon={"icons/arrow.svg"}
          iconSize={8}
          iconColor={"black"}
          onClick={() =>
            onPaginationClick(startRecord + recordsPerPage, recordsPerPage)
          }
          disabled={currentPage === pages[pages.length - 1]}
          className={classNames(styles.pageBtn, styles.next)}
        />
        {/* {!immutableRecordPerPage && (
            <Dropdown
            options={itemsPerPageOptions.map((value) => ({
                value,
                label: ${value}/${translate('tables.page')},
            }))}
            value={recordsPerPage}
            setValue={(value) => onPaginationClick(startRecord, value)}
            className={styles.recordsPerPage}
            width={120}
            />
        )} */}
      </div>
    </div>
  );
};
