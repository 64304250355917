import { columnConfigType } from "../../components/Table/Table.types"
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text"
import { StatusBadge } from "../../components/StatusBadge/StatusBadge.component"
import { TCampaignListData, TMerchantScreenCampaignListData } from "../../store/campaigns/campaigns.types"
import { capitalize } from "../../utils/capitalize"
import { dateFormatter, formatEpochToISTDateTime } from "../../utils/dateFormatter"
import { OptionsMenu } from "../../components/OptionsMenu/OptionsMenu.component"
import { OptionItem } from "../../components/OptionsMenu/OptionsMenu.types"
import styles from './campaign.module.scss'

type CombinedCampaignData = TCampaignListData | TMerchantScreenCampaignListData;

const getActionOptions = (
    data: CombinedCampaignData,
    handleResumeCampaign: (id: string) => void,
    handleCancelCampaign: (id: string) => void
): OptionItem[] => {
    const options: OptionItem[] = [];

    if (data.status === 'DRAFT') {
        options.push({
            label: 'Resume Campaign',
            action: () => handleResumeCampaign(data.id),
            icon: 'icons/play.svg'
        });
    }

    if (['SCHEDULED', 'SCHEDULED_LESS_FUND'].includes(data.status)) {
        options.push({
            label: 'Cancel Campaign',
            action: () => handleCancelCampaign(data.id),
            icon: 'icons/x-circle.svg'
        });
    }

    return options;
};

export const CampaignsTableColumns = (
    onNameClick: (name: string) => void,
    handleResumeCampaign: (id: string) => void,
    handleCancelCampaign: (id: string) => void
): columnConfigType<CombinedCampaignData>[] => [
    {
        id: 'campaign_name',
        name: 'Campaign Name',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color={'neutral-70'}
                    onClick={() => onNameClick(data.name)}
                >
                    {capitalize(data.name)}
                </Text>
            </div>
        )
    },
    {
        id: 'minutes_played',
        name: 'Minutes Played',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                >
                    {(data as TCampaignListData)?.playTime || 'N/A'}
                </Text>
            </div>
        )
    },
    {
        id: 'status',
        name: 'Status',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    <StatusBadge status={data.status.toLowerCase()} />
                </Text>
            </div>
        )
    },
    {
        id: 'campaignStartTime',
        name: 'Start Time',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {formatEpochToISTDateTime(Number(data.campaignStartTime))}
                </Text>
            </div>
        )
    },
    {
        id: 'campaignEndTime',
        name: 'End Time',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {formatEpochToISTDateTime(Number(data.campaignEndTime))}
                </Text>
            </div>
        )
    },
    {
        id: 'created_at',
        name: 'Created At',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {formatEpochToISTDateTime(Number((data as TCampaignListData).id))}
                </Text>
            </div>
        )
    },
    {
        id: 'actions',
        name: '',
        width: '60px',
        cellRenderer: (data) => {
            const options = getActionOptions(data, handleResumeCampaign, handleCancelCampaign);
            return options.length > 0 ? (
                <div className={styles.optionsCell}>
                    <OptionsMenu options={options} />
                </div>
            ) : null;
        }
    }
];

export const MerchantScreenCampaignsTableColumns = (
    onNameClick: (name: string) => void,
    handleResumeCampaign: (id: string) => void,
    handleCancelCampaign: (id: string) => void
): columnConfigType<CombinedCampaignData>[] => [
    {
        id: 'name',
        name: 'Campaign Name',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color={'neutral-70'}
                    onClick={() => onNameClick(data.name)}
                >
                    {capitalize(data.name)}
                </Text>
            </div>
        )
    },
    {
        id: 'slots',
        name: 'Slots',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                >
                    {(data as TMerchantScreenCampaignListData).slots || 'N/A'}
                </Text>
            </div>
        )
    },
    {
        id: 'status',
        name: 'Status',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    <StatusBadge status={data.status.toLowerCase()} />
                </Text>
            </div>
        )
    },
    {
        id: 'totalScreens',
        name: 'Total Screens',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.totalScreens}
                </Text>
            </div>
        )
    },
    {
        id: 'campaignStartTime',
        name: 'Start Time',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {formatEpochToISTDateTime(Number(data.campaignStartTime))}
                </Text>
            </div>
        )
    },
    {
        id: 'campaignEndTime',
        name: 'End Time',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {formatEpochToISTDateTime(Number(data.campaignEndTime))}
                </Text>
            </div>
        )
    },
    {
        id: 'actions',
        name: '',
        width: '60px',
        cellRenderer: (data) => {
            const options = getActionOptions(data, handleResumeCampaign, handleCancelCampaign);
            return options.length > 0 ? (
                <div className={styles.optionsCell}>
                    <OptionsMenu options={options} />
                </div>
            ) : null;
        }
    }
];
