import styles from './screens.module.scss';
import { columnConfigType } from "../../components/Table/Table.types"
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text"
import { TScreensListData } from "../../store/screens/screens.types"
import { capitalize } from "../../utils/capitalize"
import { SvgIcon } from "../../components/SvgIcon"
import { OptionsMenu } from '../../components/OptionsMenu/OptionsMenu.component';
import { OptionItem } from '../../components/OptionsMenu/OptionsMenu.types';

export const ScreensTableColumns = (
    onNameClick: (name: string) => void,
    handleOpenSelectMediaModal: (id: number) => void,
    handlePlayDefaultMediaModal: (mediaId: string) => void,
    handleChangeDefaultMedia: (screenId: number) => void,
): columnConfigType<TScreensListData>[] => [
    {
        id: 'screen_name',
        name: 'Screen Name',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color={'neutral-70'}
                    onClick={() => onNameClick(data.name)}
                >
                    {capitalize(data.name)}
                </Text>
            </div>
        )
    },
    {
        id: 'status',
        name: 'Status',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.status}
                </Text>
            </div>
        )
    },
    {
        id: 'location',
        name: 'Location',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}>
                    {data.location}
                </Text>
            </div>
        )
    },
    {
        id: 'tags',
        name: 'Tags',
        cellRenderer: (data) => {
            const tagColors = [
                { bg: 'Blue', text: 'BlueDark' },
                { bg: 'Green', text: 'GreenDark' },
                { bg: 'Purple', text: 'PurpleDark' },
                { bg: 'Orange', text: 'OrangeDark' }
            ];

            return (
                <div className={styles.tagsContainer}>
                    {Array.isArray(data.tags) && data.tags.map((tag, index) => (
                        <div
                            key={index}
                            className={`${styles.tagItem} ${styles[`tag${tagColors[index % tagColors.length].bg}`]}`}
                        >
                            <Text
                                variant={TextVariantsEnum.Text_sm}
                                fontWeight={FontWeightEnum.Normal}
                                className={styles[`tagText${tagColors[index % tagColors.length].text}`]}
                            >
                                {tag}
                            </Text>
                        </div>
                    ))}
                </div>
            );
        }
    },
    {
        id: 'timings',
        name: 'Timings',
        cellRenderer: (data) => {
            const formatTime = (timeString: string) => {
                const [start, end] = timeString.split('-').map(time => time.trim());

                const formattedStart = `${start.padStart(2, '0')}:00`;
                const formattedEnd = `${end.padStart(2, '0')}:00`;

                return `${formattedStart} - ${formattedEnd}`;
            };

            return (
                <div>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Normal}>
                        {data.timing ? formatTime(data.timing) : '-'}
                    </Text>
                </div>
            );
        }
    },
    {
        id: 'defaultMedia',
        name: 'Default Media',
        cellRenderer: (data: TScreensListData) => (
            <div className={styles.mediaCell}>
                {data.defaultMedia ? (
                    <SvgIcon
                        src={'./icons/play-black.svg'}
                        onClick={() => handlePlayDefaultMediaModal(data.defaultMediaId)}
                    />
                ) : (
                    <SvgIcon
                        src={'./icons/plusBlack.svg'}
                        onClick={() => handleOpenSelectMediaModal(data.id)}
                        className={styles.addMediaButton}
                    />
                )}
            </div>
        )
    },
    {
        id: 'actions',
        name: '',
        width: '60px',
        cellRenderer: (data) => {
            const options: OptionItem[] = [
                {
                    label: 'Re-Upload Default Media',
                    action: () => handleChangeDefaultMedia(data.id),
                    icon: 'icons/upload.svg'
                },
            ];

            return data.defaultMedia ? (
                <div className={styles.optionsCell}>
                    <OptionsMenu options={options} />
                </div>
            ) : null;
        }
    }
];